<template>
  <div>
    <template v-if="lineLayout == 'Compact'">
      <div class="navbar justify-content-start layout-2">
        <div>
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="navbar layout-1 justify-content-center relative">
        <img
          :src="$store.state.domain + $store.state.theme.themeLogoLine"
          class="logo-img"
          @click="toProfile"
        />
        <!-- -->
        <div
          class="lang-icon"
          v-if="$route.path == '/validatetelephone' && languageList.length > 1"
        >
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <c-btn
                flat
                :ripple="false"
                type="icon"
                v-bind="props"
                variant="text"
                size="small"
              >
                <font-awesome-icon
                  :icon="['fas', 'globe']"
                  class="f-xl color-primary"
                />
              </c-btn>
            </template>

            <v-list>
              <template v-for="lang of languageList" :key="'lang' + lang.id">
                <v-list-item @click="changeLang(lang.id)" link-class="px-3">
                  <div class="d-flex align-items-center">
                    <span class="flag-lang mr-2"> {{ lang.image_url }} </span>
                    <span data-lang="profile">{{ lang.display_name }}</span>
                    <span v-if="lang.id == langs.lang" class="ml-auto">
                      <font-awesome-icon icon="check" />
                    </span>
                  </div>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
  </div>
  <ModalLoading ref="modalLoading" />
</template>

<script>
import ModalLoading from "@/components/other/ModalLoading.vue";
export default {
  components: {
    ModalLoading
  },
  data() {
    return {
      langs: "",
      languageList: this.$store.state.languageList
    };
  },
  computed: {
    isShowing() {
      if (this.$cookies.get("token")) return true;
      return false;
    }
  },
  async created() {
    // this.lang =
    if (
      this.$store.state.languageList.length == 0 &&
      this.$route.path == "/validatetelephone"
    ) {
      const res = await this.$store.dispatch("getLanguage");
      this.languageList = res;
    }
    if (this.$cookies.isKey("lang")) {
      let lang = this.$cookies.get("lang");
      this.langs = lang;
    }
  },
  mounted() {
    this.getLang();
  },
  methods: {
    async getLang() {
      if (this.$store.state.languageList.length == 0) {
        const res = await this.$store.dispatch("getLanguage");

        this.languageList = res;
      }
    },
    toProfile() {
      if (
        this.$route.path == "/register" ||
        this.$route.path == "/review" ||
        this.$route.path == "/validatetelephone"
      ) {
        return;
      }
      this.$router.push("/profile");
    },
    async changeLang(val) {
      this.$refs.modalLoading.show();
      let index = this.languageList.findIndex(el => el.id == val);

      let payload = {
        lang: this.languageList[index].id,
        name: this.languageList[index].name,
        flag: this.languageList[index].image_url
      };
      this.$cookies.set("lang", JSON.stringify(payload));

      this.langs = payload;
      await this.$getLang();
      this.$emit("updateView");
      // this.componentKey += 1;
      // this.$forceUpdate();
      this.$refs.modalLoading.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
}

.btn-go-home {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
.navbar {
  overflow: hidden;
  position: sticky;
  top: 0;

  width: 100%;
  z-index: 5;

  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}
.layout-1 {
  height: 80px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 65px;
  }
}
.layout-2 {
  height: 70px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 50px;
  }
}
.lang-icon {
  position: absolute;
  right: 5px;
  color: var(--secondary-color);
  padding: 3px;
  border-radius: 50%;
  background: #ffffff61;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
