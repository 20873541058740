import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerComponents } from "@/components";
import vuetify from "./plugins/vuetify";

import "./style.css";
import { instance, axios, fetchConfig, campaignUrl } from "./services/axios";
import "./plugins/fontawesome";
import mitt from "mitt";
import vueDebounce from "vue-debounce";
import VueNumerals from "vue-numerals";
import VueCookies from "vue-cookies";
import liff from "@line/liff";
import swal from "sweetalert";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
import VueClipboard from "vue-clipboard2";
import QrcodeVue from "qrcode.vue";
import dictionary from "./lang";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const emitter = mitt();

const app = createApp({
  extends: App
});
app.component("VueDatePicker", VueDatePicker);
app.component("QrcodeVue", QrcodeVue);
app

  .use(VueClipboard)
  .use(vueDebounce)
  .use({
    install(Vue) {
      app.config.globalProperties.$swal = swal;
    }
  })
  .use(VueCookies)
  .use(DatetimePicker)
  .use(VueNumerals);

VueClipboard.config.autoSetContainer = true; // add this line
app.config.globalProperties.$apiKey = "ChAtA7Ds40p";
app.config.globalProperties.$formatDate = "DD MMM YYYY";
app.config.globalProperties.$formatDateTime = "DD MMM YYYY HH:mm";
if (window.Cypress) {
  app.config.globalProperties.$liff = window.Cypress.liffMock;
} else {
  app.config.globalProperties.$liff = liff;
}
app.config.globalProperties.$liffUrl = process.env.VUE_APP_LIFF_URL;

app.config.globalProperties.handleImageSrc = require("@/assets/images/default-image.png");
app.config.globalProperties.$Endpoint =
  process.env.VUE_APP_DOMAIN || window.location.origin;
app.config.globalProperties.$nissinTransaction =
  process.env.VUE_APP_TRANSACTION_API ||
  "https://api-middleware-nissin.d-dots.com/api/transactioncode/redeem";
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$axios = instance;
app.config.globalProperties.axios = axios;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$EndPointImage = campaignUrl;
app.config.globalProperties.moment = date => moment(date);

app.config.globalProperties.convertTelePhone = val => {
  if (!val) return null;

  if (val.includes("-")) {
    let value = val.split("-");
    return ` ${value[0]} ต่อ ${value[1]}`;
  }
  return ` ${val}`;
};
const originalFetch = window.fetch;
function customFetch(url, options) {
  if (
    url.toString().startsWith("https://liffsdk.line-scdn.net/xlt/") &&
    url.toString().endsWith(".json")
  ) {
    url = url + "?ts=" + Math.random();
  }
  return originalFetch(url, options);
}
window.fetch = customFetch;
registerComponents(app);
async function initializeApp() {
  const config = await fetchConfig();

  if (config) {
    let location = window.location.hostname;
    let brand = location.split("-")[0];
    let liffId = "";
    var liffMock = liff;
    if (brand == "5t8z5pcj") liffId = "1656234832-gyrQbQVW";
    else liffId = config.memberLiffUrl.split("/")[3];

    if (window.Cypress) {
      liffId = window.Cypress.liffMock.liffId;
      liffMock = window.Cypress.liffMock;
      app.config.globalProperties.$liffId = window.Cypress.liffMock.liffId;
    } else {
      app.config.globalProperties.$liffId = liffId;
    }

    instance(`/api/v1/Setting/theme`).then(async data => {
      setProperty({ data, config });
      await getLanguage(data);

      app.config.globalProperties.$getLang = () => getLanguage(data);

      liffMock.init({ liffId: liffId }).then(() => {
        app.use(router).use(store).use(vuetify).mount("#app");
      });
    });
  } else {
  }
}
function setProperty({ data, config }) {
  store.commit("setTheme", data);
  if (config.custom_js) convertCustomJs(config.custom_js);
  genarateFont(config);
  app.config.globalProperties.$config = config;
  let brand = window.location.hostname.split("-")[0];
  app.config.globalProperties.$BRAND =
    brand.charAt(0).toUpperCase() + brand.slice(1);
  app.config.globalProperties.displayUpcomming =
    config.display_upcomming_redeem == 1 ? true : false;
  app.config.globalProperties.displayMission =
    config.line_display_mission == 1 ? true : false;
  app.config.globalProperties.rewardProgress =
    config.display_reward_progress == 1 ? true : false;
  app.config.globalProperties.yearFormat = data.result.year_format;
  app.config.globalProperties.lineLayout = data.result.line_layout;
}
function convertCustomJs(a) {
  try {
    if (a)
      for (var element of a.split("</script>")) {
        if (element) {
          if (!element.includes("<script")) {
            element = `<script>${element}</script>`;
          }
          const fragment = document
            .createRange()
            .createContextualFragment(element);
          document.body.appendChild(fragment);
        }
      }
  } catch (err) {}
}
async function getLanguage(data, lang_id = 1) {
  const res = await store.dispatch("getLanguage");
  // this.languageList = res;
  if (!VueCookies.isKey("lang")) {
    VueCookies.set(
      "lang",
      JSON.stringify({
        lang: 1,
        name: "th",
        flag: "🇹🇭"
      })
    );
    lang_id = 1;
  } else {
    let lang = VueCookies.get("lang");
    if (res.find(el => el.id == lang.lang)) lang_id = lang.lang;
    else {
      VueCookies.set(
        "lang",
        JSON.stringify({
          lang: 1,
          name: "th",
          flag: "🇹🇭"
        })
      );
      lang_id = 1;
    }
  }

  const result = await instance(
    `/api/v1/setting/display_language/label/${lang_id}`
  );
  var lang = "th";
  if (VueCookies.isKey("lang")) {
    lang = VueCookies.get("lang").name;
  }
  if (data.result.year_format == 1) {
    app.config.globalProperties.$moment = date =>
      moment(date)
        .locale(lang == "zh" ? "zh-cn" : lang)
        .add("543", "year");
  } else
    app.config.globalProperties.$moment = date =>
      moment(date).locale(lang == "zh" ? "zh-cn" : lang);
  // if (result.detail.length == 0 || !result.detail) return [];
  let obj = {};
  result.detail.map(el => {
    return (obj[el.label_name] = el.name);
  });
  app.config.globalProperties.$t = val => dictionary(obj, val);
  return obj;
}
function genarateFont(data) {
  let bodyStyles = document.body.style;
  if (data.font_size == "Large") {
    bodyStyles.setProperty("--text-xs", "12px");
    bodyStyles.setProperty("--text-sm", "14px");
    bodyStyles.setProperty("--text-md", "16px");
    bodyStyles.setProperty("--text-lg", "18px");
    bodyStyles.setProperty("--text-xl", "22px");
  }
  const fontBold = "/font/Kanit-SemiBold.ttf";
  const fontRegular = "/font/Kanit-Regular.ttf";
  // if (data.custom_font) {
  var newStyle = document.createElement("style");
  const fontFaceRule = `
        @font-face {
            font-family: 'CustomFont';
            src: url('${
              data.custom_font
                ? store.state.domain + data.custom_font.substring(1)
                : fontRegular
            }') format('truetype');
            font-weight: 400;
            font-display: swap;
        }
    `;

  const fontFaceRuleBold = `
        @font-face {
            font-family: 'CustomFont';
            src: url(${
              data.custom_font_bold
                ? store.state.domain + data.custom_font_bold.substring(1)
                : fontBold
            }) format('truetype');
            font-weight: 600;
            font-display: swap;
        }
    `;
  newStyle.appendChild(
    document.createTextNode(fontFaceRule + fontFaceRuleBold)
  );

  document.head.appendChild(newStyle);
}
initializeApp();

export { liff };
